var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "navbar-container d-flex content align-items-center"
  }, [_c('ul', {
    staticClass: "nav navbar-nav d-xl-none"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('b-link', {
    staticClass: "nav-link",
    on: {
      "click": _vm.toggleVerticalMenuActive
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MenuIcon",
      "size": "21"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
  }, [_c('bookmarks')], 1), _c('b-navbar-nav', {
    staticClass: "nav align-items-center ml-auto"
  }, [_c('locale'), _c('dark-Toggler', {
    staticClass: "d-none d-lg-block"
  }), _c('search-bar'), _c('cart-dropdown'), _c('notification-dropdown'), _vm.isUserLoggedIn() ? _c('user-dropdown') : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }