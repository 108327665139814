<template>
  <div
    class="vertical-layout h-100"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
  >
    <!-- Navbar -->
    <b-navbar
      :toggleable="false"
      :variant="navbarBackgroundColor"
      class="header-navbar navbar navbar-shadow align-items-center"
      :class="[navbarTypeClass]"
    >
      <slot
        name="navbar"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :navbarBackgroundColor="navbarBackgroundColor"
        :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']"
      >
        <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
      </slot>
    </b-navbar>
    <!--/ Navbar -->

    <!-- Vertical Nav Menu -->
    <vertical-nav-menu
      v-if="!isNavMenuHidden"
      :is-vertical-menu-active="isVerticalMenuActive"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
    >
      <template #header="slotProps">
        <slot
          name="vertical-menu-header"
          v-bind="slotProps"
        />
      </template>
    </vertical-nav-menu>
    <!-- /Vertical Nav Menu -->

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- Content -->

    <!-- CONTENT TYPE: Left -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->

    <div
      class="start-chat-area"
      style="display: none"
    >
      <!-- Start Chat Logo -->
      <div
        class="start-chat-icon"
        @click="toggleChat"
      >
        <feather-icon
          icon="MessageSquareIcon"
          size="56"
        />
      </div>

      <!-- Start Chat Window -->
      <div
        class="start-chat-window"
      />
    </div>

    <!-- Footer -->
    <footer
      class="footer footer-light"
      :class="[footerTypeClass]"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
    <!-- /Footer -->

    <slot name="customizer" />
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from '@vue/composition-api'
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar } from 'bootstrap-vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached
from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import LayoutContentRendererDefault from '@/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import Chat from '@/views/chat/Chat.vue'
import store from '@/store'
import gql from 'graphql-tag'
import { apolloClient } from '@/libs/apollo'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from './useVerticalLayout'
import mixinVerticalLayout from './mixinVerticalLayout'

export default {
  components: {
    Chat,
    // AppBreadcrumb,
    AppNavbarVerticalLayout,
    AppFooter,
    VerticalNavMenu,
    BNavbar,
    LayoutContentRendererLeftDetached,
    LayoutContentRendererLeft,
    LayoutContentRendererDefault,
  },
  mixins: [mixinVerticalLayout],
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
  },
  setup() {
    const {
      routerTransition,
      navbarBackgroundColor,
      navbarType,
      footerType,
      isNavMenuHidden,
    } = useAppConfig()

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    const toggleChat = () => {
      this.$store.commit('chat/TOGGLE_CHAT')
    }

    const messages = ref([])

    onMounted(async () => {
      await store.dispatch('user/fetchMe')

      const me = await store.getters['user/getMe']

      console.log(me?.chats)

      if (me?.chats && me.chats.length > 0) {
        me.chats.forEach(chat => {
          apolloClient.subscribe({
            query: gql`
              subscription MessageSent($chat_id: Int!) {
                messageSent(chat_id: $chat_id){
                  id
                  chat_id
                  message
                }
              }
            `,
            variables: { chat_id: Number(chat.id) },
          })
            .subscribe({
              next: ({ data }) => {
                const newMessage = data.messageSent
                messages.value.push(newMessage)
              },
              error: err => console.error('Subscription error:', err),
            })
        })
      }
    })

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,

      // App Config
      routerTransition,
      navbarBackgroundColor,
      isNavMenuHidden,

      // Methods
      toggleChat,
      messages,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";

.start-chat-icon {
  position: fixed;
  bottom: 50px;
  right: 25px;
  height: 50px;
  background-color: #283046;
  width: 50px;
  border-radius: 50%;
  padding: 10px;

  & svg {
    height: 100%;
    width: 100%;
  }
}

.start-chat-window {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50%;
  height: 500px;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  padding: 10px;
}

</style>
